import { graphql } from 'gatsby'
import { GatsbyCategoryPage } from '../features/products'

export default GatsbyCategoryPage

export const pageQuery = graphql`
  query ProductsByCategory($name: String!) {
    ...ProductListItemsByCategoryFragment
  }
`
